cx-breadcrumb {
  padding: 0px 0px 0px 10px;
}

.header cx-site-context-selector select {
  background: transparent;
}

.header cx-site-context-selector select option {
  color: #0057a6;
}

.header div.childs > nav > cx-generic-link > a {
  font-family: "NotoSans", sans-serif;
  font-size: 12px;
  text-decoration: none;
  color: #0057a6;
  font-weight: normal;
  font-family: "NotoSans", sans-serif;
  border-bottom: 1px solid #edf6fb !important;
  padding: 9px 0 10px;
}

.header cx-navigation-ui.flyout h5 a,
cx-navigation-ui.flyout cx-generic-link a {
  color: #0057a6;
}

cx-breadcrumb nav {
  font-size: 0.85rem;
  padding: 20px 12px 20px 0px;
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #337ab7 !important;
  font-size: 11px;
  font-family: "NotoSans", sans-serif !important;
}

.BottomHeaderSlot cx-breadcrumb nav span {
  color: #337ab7;
}

header div.childs > nav > cx-generic-link > a {
  font-family: "NotoSans", sans-serif;
  font-size: 14px;
  color: #0057a6;
}

header div.childs > nav > cx-generic-link > a:hover {
  color: #0057a6;
}

.BottomHeaderSlot cx-breadcrumb {
  background-color: #edf6fb;
}

cx-breadcrumb h1 {
  font-size: 32px;
  white-space: normal;
  overflow: hidden;
  margin: 0px 0px 0px 5px !important;
  text-overflow: ellipsis;
  text-align: left;
  padding: 0px 0px 19px 0px;
  color: #337ab7;
  font-family: "Poppins-Medium", sans-serif !important;
}

cx-breadcrumb nav span a:hover {
  color: #337ab7;
  cursor: pointer;
  text-decoration: underline;
}

cx-breadcrumb nav span a header .SiteLogin {
  display: none;
}

cx-category-navigation {
  color: #0057a6;
}

@media (min-width: 992px) {
  .NavigationBar cx-navigation-ui.flyout h5 {
    margin-left: 40px;
  }
  header .SiteContext cx-paragraph p {
    color: #bfc4c8;
    font-size: 11px;
  }
  header .SiteLinks cx-link cx-generic-link a {
    color: white;
  }
  header .SiteLogin cx-login {
    display: none;
  }
  header .SiteLogin {
    padding: 0.5rem 1rem;
    display: none;
    width: 20px;
  }
  header {
    //background-color: #ffffff;
  }
  header .header {
    max-width: 1360px !important;
    margin: 0 auto !important;
  }
}

header {
  box-shadow: 0 0px 5px rgb(23 114 183 / 20%);
  z-index: 9;
}

header .SiteLogo {
  width: 330px;
  margin: 0px 0px 0px 50px;
}

.NavigationBar cx-navigation-ui.flyout h5 {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: currentColor;
  font-family: "Poppins-Medium", sans-serif;
}

.header cx-media img {
  background: transparent;
}

.NavigationBar cx-navigation-ui {
  min-height: 67px;
  padding: 10px 0px 20px 14px;
}

cx-category-navigation h5,
cx-category-navigation cx-navigation-ui > nav > cx-generic-link {
  text-transform: capitalize;
  font-weight: normal;
  font-size: 14px;
}

@media (min-width: 992px) {
  header .SiteLogo {
    width: 784px;
    margin: 0px 0px 0px 50px;
  }
  header .SiteLogo > cx-banner cx-generic-link a .is-initialized {
    width: 330px;
  }
  cx-navigation-ui.flyout > nav > h5 {
    margin-top: 3px;
    padding-top: 20px;
    padding-inline-end: 15px;
    padding-inline-start: 0;
  }
}

header .NavigationBar {
  flex: 100%;
  margin: 0px 0px -33px 0px;
}

@media (min-width: 992px) {
  header .SiteLinks {
    justify-content: flex-end;
    height: 20px;
    padding: 0px 50px 0px 0px;
  }
  header .SiteLinks cx-link cx-generic-link a {
    height: 40px;
    width: auto;
    font-weight: normal;
    color: #ffffff;
    padding: 10px 0px 0px 0px;
    font-size: 11px;
    font-weight: bold;
  }
  header .SiteLinks cx-link {
    padding: 0px;
  }
}

.header .SearchBox cx-searchbox label {
  height: 44px;
  border-radius: 4px;
}

@media (min-width: 768px) {
  cx-searchbox > * {
    border: 1px solid #d4d9de;
    box-shadow: inset 0 0 2px 0 #d4d9de !important;
    background-color: #ffffff;
    position: relative;
  }
}

cx-searchbox label input {
  color: #999999;
  font-size: 14px;
  z-index: 0 !important;
}

cx-icon fas fa-shopping-cart {
  width: 30px;
  height: 30px;
  display: block;
  cursor: pointer;
}

header cx-site-context-selector {
  padding: 0px 0px 0px 40px;
  margin-top: 0px;
}

@media (min-width: 992px) {
  header .SiteLinks cx-navigation cx-navigation-ui.flyout > nav > h5 {
    margin: 1px 19px 0px 0px;
    font-size: 11px;
    font-weight: bold;
    padding-top: 10px;
    color: #ffffff;
  }
  .header .SearchBox cx-searchbox label {
    width: 28vw;
  }
}

@media (min-width: 992px) {
  header .SiteLinks {
    height: 40px;
    font-size: 11px;
    margin: 0px -10px 0px 0px;
    font-weight: bold;
    color: #ffffff;
    background-color: #2a343f !important;
  }
  header .SiteContext {
    justify-content: flex-start;
    height: 40px;
    padding: 10px 0px 0px 64px;
    font-size: 11px;
    margin: 0px 0px 0px -10px;
    background-color: #2a343f !important;
  }
}

@media (min-width: 992px) {
  cx-site-context-selector select {
    background: transparent;
    font-size: 11px;
    font-weight: 700;
    font-family: "NotoSansBold", sans-serif;
  }
  cx-site-context-selector select option {
    color: #0057a6;
  }
}

header .SearchBox .search {
  color: #0057a6;
  font-size: 18px;
}

header .SearchBox {
  margin: 0px;
  padding: 20px 0px 0px 0px;
}

header .SiteLogo {
  padding: 20px 0px 0px 0px;
}

header .SiteContext cx-site-context-selector {
  color: #ffffff;
}

header .SiteLinks cx-link cx-generic-link a {
  width: auto;
  margin: 0px;
}

header .SiteLinks cx-link cx-generic-link a:hover {
  text-decoration: none;
}

header .SiteContext {
  justify-content: flex-start;
}

header .SiteContext,
header .SiteLinks {
  font-size: 0.75rem;
  color: #ffffff;
}

@media screen and (min-width: 300px) and (max-width: 650px) {
  header .header {
    background-color: #ffffff;
  }
  header .SiteContext {
    background-color: #2a343f;
    display: flex;
    padding: 0px 157px 0px 0px;
    font-size: 11px;
    height: 28px;
    min-height: 40px;
    color: #bfc4c8;
    header .SiteContext cx-site-context-selector {
      display: none;
    }
  }
  header .SiteContext > *,
  header .SiteLinks > * {
    display: flex;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  header .SiteContext {
    background-color: #2a343f !important;
  }
  header.is-expanded .PreHeader {
    background-color: #2a343f !important;
  }
  header .PreHeader {
    background-color: #fff;
    height: 76px;
    padding: 20px 0px 0px 0px;
  }
  header .MiniCart {
    height: 76px;
  }
  header .SiteContext cx-site-context-selector > label {
    position: relative;
    top: 10px;
    left: 20px;
  }
  header .SiteContext :nth-child(3) {
    display: block !important;
    height: 25px;
    width: 17%;
  }
  .header .SiteLinks cx-link:nth-of-type(2) {
    display: none;
  }
  .LoginPageTemplate .LeftContentSlot .user-form {
    left: 30px;
  }
  cx-page-layout cx-page-slot.MiniCart {
    padding: 25px 24px 0px 18px !important;
  }
  header cx-navigation-ui.flyout h5:hover,
  cx-navigation-ui.flyout cx-generic-link:hover {
    color: #ffffff;
  }
  header .SiteLinks cx-link cx-generic-link a {
    padding: 0px 0px !important;
    width: 734px !important;
  }
  header .SiteLinks cx-navigation-ui nav {
    padding: 0px 30px !important;
    width: 100%;
    margin: 0px 0px 0px 1px !important;
  }
  header .SiteLinks > cx-navigation {
    margin: 0px -1px 0px -1px;
    width: 17%;
  }
  header .SiteContext > *,
  header .SiteLinks > * {
    height: 40px;
    background-color: #2a343f !important;
  }
  header .SiteLogo,
  header .MiniCart,
  header .SearchBox,
  header .SiteLogin {
    background-color: #ffffff !important;
  }
  header .SiteContext {
    width: 300px;
    height: 40px;
  }
  header .SiteLinks {
    height: 40px;
    display: contents;
  }
  header .SiteContext :nth-child(2) {
    display: block !important;
    padding: 10px 0px 0px 10px !important;
    width: 12.6%;
    border-top: 0px !important;
    margin: 0px !important;
  }
  header .SiteContext :nth-child(2) {
    padding: 0px !important;
    border-top: 0 !important;
    width: 30% !important;
  }
  header cx-page-layout cx-page-slot {
    display: flex;
    flex-wrap: initial;
  }
  header cx-navigation-ui.flyout > nav > h5 {
    width: 78px;
    .fa-angle-down {
      margin: 1px 0px 5px 0px;
    }
  }
  cx-navigation-ui.flyout > nav > cx-generic-link {
    width: 102px;
  }
  header cx-navigation-ui.flyout > nav > h5,
  cx-navigation-ui.flyout > nav > cx-generic-link {
    text-transform: capitalize;
    font-size: 11px;
    color: #ffffff;
    height: 41px;
    padding: 13px 0px 11px 0px !important;
  }
  header cx-navigation-ui cx-icon:before {
    font-size: 11px;
  }
  header .MiniCart {
    padding: 20px 0px 0px 0px;
    width: 14.4% !important;
    margin: 0px 20px 0px 0px;
  }
  header .SiteLogo {
    padding: 32px 0px 0px 0px !important;
    height: 76px !important;
    margin: 0px !important;
    width: 38%;
  }
  header .SearchBox {
    width: 40.3% !important;
    height: 76px !important;
  }
  .header cx-media img {
    background: transparent;
    margin: -10px 0px 0px 0px !important;
    width: 277px;
    height: 40px;
  }
  .header .SearchBox cx-media img {
    width: 54px !important;
    max-width: 54px !important;
  }
  header .SiteLinks cx-link {
    display: inline-block;
    width: 21%;
    padding: 10px 0px 0px 0px;
    text-align: left;
  }
  .header cx-searchbox label {
    height: 44px;
    border-radius: 4px;
    box-shadow: inset 0 0 4px 0.5px #d4d9de !important;
    border: 1px solid #d4d9de;
  }
  header .SiteContext cx-paragraph {
    width: 34.7%;
    padding: 10px 10px 10px 16px !important;
  }
  @media (max-width: 991.98px) {
    header cx-navigation-ui.flyout {
      color: #0057a6;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  header .SiteLinks cx-navigation cx-navigation-ui {
    padding: 0px 0px 0px 0px !important;
  }
  header .header {
    background-color: #fff !important;
  }
  .header .SiteLinks cx-link:nth-of-type(2) {
    display: none;
  }
  .LoginPageTemplate .LeftContentSlot .user-form {
    left: 20px !important;
  }
  .header .SearchBox cx-searchbox label {
    width: 280px !important;
    min-width: 181px !important;
  }
  cx-page-layout.LoginPageTemplate {
    min-height: 482px;
    width: 100% !important;
  }
  header .SiteLinks {
    justify-content: flex-end;
    padding: 0px 0px 0px 0px !important;
  }
  cx-navigation-ui.flyout > nav > .wrapper {
    margin: 3px -8px !important;
  }
  header .SiteLogo {
    width: 531px;
    margin: 0px 0px 0px 50px;
  }
  header .SiteLogo > cx-banner cx-generic-link a .is-initialized {
    width: 330px;
  }
  header .SiteContext {
    padding: 11px 0px 0px 50px;
    background-color: #2a343f !important;
    flex: none !important;
  }
  header .SiteLogo {
    width: 529px;
    margin: 0px 0px 0px 36px !important;
  }
  .MiniCart app-mini-cart > .cart-icons {
    padding-right: 35px;
  }
  .NavigationBar cx-navigation-ui.flyout h5 {
    margin-left: 40px;
  }
  header .SiteLinks cx-link cx-generic-link a {
    margin: 0px 32px 0px 0px !important;
    padding: 10px 15px 0px 15px;
    width: 382px;
    background-color: #2a343f !important;
    height: 39px !important;
  }
  .BottomHeaderSlot {
    padding: 0px 30px 0px 0px !important;
  }
  
  header .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 25px;
    height: 2px;
    background-color: #0057a6;
  }
  header .SiteLinks cx-navigation cx-navigation-ui.flyout > nav > h5 {
    font-size: 11px;
    font-weight: bold;
    color: #ffffff;
    padding: 10px 10px 10px 10px !important;
    background-color: #2a343f !important;
  }
  header .MiniCart {
    padding: 20px 0px 0px 0px !important;
  }
  header .MiniCart {
    padding: 20px 0px 0px 0px !important;
  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  header .SiteLinks cx-navigation-ui nav {
    display: none;
  }
  header cx-navigation-ui.flyout h5:hover {
    color: #fff;
  }
  header .SiteContext cx-site-context-selector {
    display: block;
  }
  header .SiteLinks cx-link cx-generic-link a {
    width: 355px;
  }
  header .SiteContext :nth-child(2) {
    display: block;
    margin: -1px -4px 0px 0px;
  }
  .header .SiteContext :nth-child(3) {
    display: none;
  }
  cx-navigation-ui.flyout > nav > h5 {
    width: 116%;
    font-size: inherit;
    height: 40px;
    margin: 0px 0px 0px 0px;
  }
  app-quick-order cx-searchbox label {
    max-width: 278px !important;
    min-width: 312px !important;
    width: 312px !important;
  }
  header .SiteLinks cx-link {
    display: block;
    height: 40px !important;
    width: 100%;
    background-color: #2a343f !important;
    padding: 10px 13px 0px 12px;
    margin: 0px 0px 0px 0px;
  }
  header .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 25px;
    height: 2px;
    background-color: #0057a6;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  cx-page-layout.LoginPageTemplate {
    min-height: 548px !important;
    width: 100% !important;
  }
  cx-page-layout.LoginPageTemplate .customer-login {
    width: 96% !important;
    margin: 0px 0px 0px -17px;
    display: block !important;
  }
  cx-breadcrumb h1 {
    font-size: 24px !important;
  }
  cx-breadcrumb nav span a {
    text-transform: capitalize;
    color: inherit;
    padding: 5px 5px;
  }
  cx-breadcrumb nav {
    padding: 20px 0px 20px 0px;
  }
  .BottomHeaderSlot cx-breadcrumb {
    padding: 0px 0px 0px 10px;
  }
  header .SiteLogo {
    height: 26.06px;
    margin: 0px !important;
  }
  header .SearchBox {
    width: 35%;
    margin: 0px;
    padding: 0px 15px 15px 15px;
  }
  .header cx-searchbox label {
    height: 44px;
    border-radius: 4px;
    box-shadow: inset 0 0 4px 0.5px #d4d9de !important;
    border: 1px solid #d4d9de;
  }
  .header cx-media img {
    background: transparent;
    margin: 0px 0px 0px 0px;
  }
  header .MiniCart {
    padding: 10px 0px 10px 0px;
    margin: 0px !important;
  }
}

@media (max-width: 991.98px) {
  header .SiteContext,
  header .SiteLinks {
    background-color: #2a343f !important;
  }
  header .SiteContext,
  header .SiteLinks {
    display: contents;
  }
  header .SiteContext cx-site-context-selector {
    display: none;
  }
  header .SiteContext cx-paragraph {
    display: inline-block;
    padding: 10px;
    background-color: #2a343f !important;
    height: 40px;
    color: #bfc4c8;
  }
  header {
    background-color: white;
  }
  .header cx-searchbox label {
    margin: 15px 0px 15px 0px;
    height: 44px;
    border-radius: 4px;
    box-shadow: inset 0 0 4px 0.5px #d4d9de !important;
    border: 1px solid #d4d9de;
  }
  cx-media img {
    max-width: fit-content;
  }
  header .SearchBox {
    margin: 0px;
    padding: 0px;
  }
  header .SiteLogo {
    margin: 0px !important;
  }
}

@media (max-width: 991.98px) {
  header .SiteContext cx-site-context-selector {
    display: block;
  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  .header cx-media img {
    width: 277px;
  }
  cx-navigation-ui.flyout .childs[columns="1"] {
    column-count: 1;
    padding: 10px;
  }
  .header .SiteLinks {
    display: contents !important;
  }
  header .header {
    background-color: #2a343f !important;
  }
  header .SiteLinks cx-link cx-generic-link a {
    padding: 5px 20px 0px 0px !important;
    width: 753px !important;
    margin: 0px 0px 0px 0px;
    background-color: #2a343f !important;
    color: #fff;
    font-size: 12px;
    font-family: "NotoSansBold", sans-serif;
    text-decoration: none;
    height: 36px;
  }
  header .SiteContext cx-site-context-selector {
    display: block;
    padding: 10px;
    background-color: #2a343f !important;
    width: 16%;
    margin: 0px 0px 7.2px 0px;
  }
  header
    .SiteContext
    .EmptyCartMiddleContent
    app-quick-order
    cx-searchbox
    label {
    width: 27vw;
    min-width: 750px !important;
    max-width: 750px !important;
  }
  .customer-login {
    margin: 0px 0px 0px 0px !important;
  }
  cx-page-layout.LoginPageTemplate {
    min-height: 499px !important;
    width: 101%;
  }
  header .SiteLogo {
    width: 364px !important;
  }
  .MiniCart app-mini-cart > .cart-icons {
    padding-right: 21px !important;
  }
  .header cx-searchbox label {
    width: 110% !important;
  }
  header .header .SiteContext cx-paragraph > p {
    color: #bfc4c8;
    font-size: 11px;
  }
  header .SiteLinks cx-navigation cx-navigation-ui.flyout > nav > h5 {
    text-transform: capitalize;
    font-size: 12px;
    color: #fff;
    font-family: "NotoSansBold", sans-serif;
    text-decoration: none;
  }
  .NavigationBar cx-navigation-ui.flyout h5 {
    margin-left: 0px !important;
  }
  .NavigationBar cx-navigation-ui {
    height: 953px !important;
  }
  .PreHeader cx-hamburger-menu {
    margin: 0px 0px -4px 0px;
  }
  header .header .SiteLogin {
    display: none;
  }
  header .header .SiteLogo {
    background-color: white;
    height: 75px;
    padding: 20px 0px 0px 0px;
    width: 330px;
  }
  header .SiteLinks cx-link {
    background-color: #2a343f !important;
    text-align: left;
    padding: 0px 0px 0px 10px;
    height: 40px;
  }
  header.is-expanded .PreHeader {
    display: flex;
    flex: 1 var(--cx-flex-basis, 100%);
    background-color: #2a343f !important;
  }
  header .cx-hamburger {
    float: right;
  }
  header .SiteContext cx-paragraph {
    width: 31%;
    float: right;
    padding: 10px 0px 10px 16px;
  }
  header .SiteLinks cx-navigation cx-navigation-ui.flyout > nav > h5 {
    padding: 8px 17px 8px 6px !important;
    height: 35px;
    margin: 0px -1px 0px 0px;
    width: 119%;
  }
  header .SiteLinks > cx-navigation {
    width: 15%;
    margin: 0px -1px 0px -1px;
  }
  .header .MiniCart {
    padding: 20px 0px 20px 0px !important;
    background-color: #fff;
    height: 75px;
  }
  .header .SearchBox {
    background-color: #fff;
    padding: 0px 20px 0px 10px;
    height: 75px;
    width: 305px;
  }
  
  .header .PreHeader {
    background-color: white;
    height: 75px;
    padding: 18px 0px 0px 0px;
    width: 62px;
  }
}

@media screen and (min-width: 320px) and (max-width: 760px) and (orientation: portrait) {
  header .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 25px;
    height: 2px;
    background-color: #0057a6;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  cx-navigation-ui.flyout > nav > h5 {
    width: 162px;
    height: 40px;
  }
  header .SiteLogo {
    width: 205px;
    height: 26.06px;
    margin: 0px !important;
    padding: 0px !important;
  }
  header .SearchBox {
    margin: 0px;
    padding: 0px 10px 0px 7px;
    width: 100%;
  }
  header .SiteContext cx-paragraph {
    width: 100%;
    padding: 10px 10px 10px 16px;
    border-bottom: 1px solid #fff;
  }
  .header cx-searchbox label {
    height: 44px;
    border-radius: 4px;
    box-shadow: inset 0 0 4px 0.5px #d4d9de !important;
    border: 1px solid #d4d9de;
  }
  header .MiniCart {
    padding: 10px 0px 10px 0px;
    margin: 0px !important;
  }
}


//hamburger
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  header .SiteLinks cx-navigation-ui nav {
    display: block;
  }
  header .header {
    background-color: #2a343f !important;
  }
  .header .SiteLinks cx-link:nth-of-type(1) {
    width: 99.6%;
    height: 40px;
    padding: 10px;
  }
  app-quick-order cx-searchbox label {
    max-width: 278px !important;
    min-width: 225px !important;
    width: 235px !important;
  }
  header .SiteLinks cx-link {
    display: block;
  }
  cx-page-slot.SiteLogin {
    display: none !important;
  }
  cx-page-layout.LoginPageTemplate .customer-login {
    width: 43% !important;
    position: fixed;
  }
  header cx-navigation-ui.flyout.is-open nav.is-open > .wrapper {
    height: auto;
    background: #ffffff;
    width: 199px !important;
    margin: 0px -26px !important;
  }
  header.is-expanded .PreHeader {
    z-index: 4;
    flex: 1 1 var(--cx-flex-basis, 100%);
    margin: 0px 0px -4px 0px !important;
  }
  header .cx-hamburger {
    float: right;
    margin: 0px 0px 0px 0px !important;
  }
  .header cx-hamburger-menu .hamburger-inner::after {
    cx-page-slot.SiteContext > cx-paragraph {
      display: none;
    }
  }
}

.noDisplay {
  display: none !important;
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  header.is-expanded .PreHeader {
    z-index: 4;
    flex: 1 1 var(--cx-flex-basis, 100%);
    margin: 0px 5px 0px 0px;
    background-color: #2a343f;
  }
  cx-navigation-ui.flyout h5 .wrapper .childs cx-generic-link {
    border-bottom: none;
  }
  .NavigationBar cx-navigation-ui {
    height: 986px !important;
    color: #0057a6 !important;
  }
  header .cx-hamburger {
    float: right;
  }
  .header .SearchBox cx-searchbox label {
    width: 343px !important;
  }
}

@media (min-width: 992px) {
  .header .NavigationBar cx-navigation-ui.flyout cx-generic-link a {
    font-size: 14px !important;
    margin-left: 40px;
  }
  header .SiteLogo {
    width: 56.5%;
    margin: 0px 0px 0px 50px;
  }
  cx-navigation-ui.flyout > nav > .wrapper {
    padding: 3px 16px;
    margin: -12px -18px;
    box-shadow: 0 2px 3px 0px rgb(23 114 183 / 20%);
    border: none;
  }
}

cx-navigation-ui.flyout cx-generic-link a {
  font-size: 12px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  color: #0057a6 !important;
}

@media (min-width: 992px) {
  nav > div > cx-generic-link {
    padding: 7px 0;
  }
}

@media (max-width: 991.98px) {
  cx-navigation-ui.flyout.is-open nav.is-open {
    z-index: 1;
    display: initial;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: portrait) {
  header .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 25px;
    height: 2px;
    background-color: #0057a6;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  cx-breadcrumb h1 {
    font-size: 24px !important;
  }
  cx-breadcrumb nav span a {
    text-transform: capitalize;
    color: inherit;
    padding: 5px 5px;
  }
  cx-breadcrumb nav {
    padding: 20px 0px 20px 0px;
  }
  .SearchResultsGridPageTemplate .product-page .main .content-right {
    flex: initial;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 346px !important;
  }
  .BottomHeaderSlot cx-breadcrumb {
    padding: 0px 0px 0px 10px;
  }
  header .SiteLogo {
    height: auto;
    margin: 0px !important;
    padding: 0px !important;
  }
  header .SearchBox {
    width: 100%;
    margin: 0px;
    padding: 0px 15px 15px 15px;
  }
  .header cx-searchbox label {
    height: 44px;
    border-radius: 4px;
    box-shadow: inset 0 0 4px 0.5px #d4d9de !important;
    border: 1px solid #d4d9de;
    width: 327px !important;
  }
  .header cx-media img {
    background: transparent;
    margin: 0px 0px 0px 0px;
  }
  .header .SiteLogo cx-media img {
    width: 205px;
    max-width: 205px;
  }
  header .MiniCart {
    padding: 10px 0px 10px 0px;
    margin: 0px !important;
  }
  header.is-expanded .PreHeader {
    z-index: 4;
    flex: 1 1 var(--cx-flex-basis, 100%);
    margin: 0px 5px 0px 0px;
  }
  cx-navigation-ui.flyout h5 .wrapper .childs cx-generic-link {
    border-bottom: none;
  }
  .NavigationBar cx-navigation-ui {
    height: 986px !important;
    color: #0057a6 !important;
  }
  header .cx-hamburger {
    float: right;
  }
  header .SiteContext:nth-child(2) {
    width: 100%;
    height: 40px;
    background-color: #2a343f !important;
    padding: 10px 10px 10px 10px;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: landscape) {
  header .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 25px;
    height: 2px;
    background-color: #0057a6;
  }
  header .SiteContext cx-site-context-selector {
    width: 7% !important;
  }
  .header cx-media img {
    width: 277px;
  }
  header .SiteLogo {
    width: 303px !important;
  }
  header .SearchBox {
    background-color: #fff;
    padding: 0px 20px 0px 23px !important;
    height: 75px;
    width: 640px !important;
  }
  .MiniCart app-mini-cart > .cart-icons {
    padding-right: 21px !important;
  }
  .header .SearchBox cx-searchbox label {
    width: 595px !important;
    min-width: 595px !important;
    max-width: 595px !important;
  }
  header .header .SiteContext cx-paragraph > p {
    color: #bfc4c8;
    font-size: 11px;
  }
  header .SiteLinks cx-navigation cx-navigation-ui.flyout > nav > h5 {
    text-transform: capitalize;
    font-size: 12px;
    background-color: #2a343f !important;
    font-family: "NotoSansBold", sans-serif;
    text-decoration: none;
  }
  .NavigationBar cx-navigation-ui.flyout h5 {
    margin-left: 0px !important;
  }
  .NavigationBar cx-navigation-ui {
    height: 953px !important;
  }
  .PreHeader cx-hamburger-menu {
    margin: 0px 0px -4px 0px;
  }
  header .header .SiteLogin {
    display: none;
  }
  header .header .SiteLogo {
    padding: 20px 0px 0px 10px;
  }
  header .SiteLinks cx-link {
    background-color: #2a343f !important;
    width: 100% !important;
    text-align: left;
    padding: 0px 0px 0px 10px !important;
    border: none;
    height: 40px;
  }
  header.is-expanded .PreHeader {
    display: flex;
    flex: 1 var(--cx-flex-basis, 100%);
  }
  header .cx-hamburger {
    float: right;
  }
  header .SiteContext cx-paragraph {
    width: 40% !important;
    float: right;
    padding: 10px 0px 10px 16px;
  }
  .header .SiteLinks {
    display: block;
  }
  header .SiteLinks cx-link cx-generic-link a {
    padding: 10px 20px 0px 0px !important;
    width: 630px !important;
  }
  header .SiteLinks cx-navigation cx-navigation-ui.flyout > nav > h5 {
    padding: 8px 17px 8px 6px !important;
    height: 41px;
    margin: 0px -1px 0px 0px;
  }
  header .SiteLinks > cx-navigation {
    width: 15%;
    margin: 0px -1px 0px -1px;
  }
  .header .MiniCart {
    padding: 20px 0px 20px 0px !important;
    width: 33.4%;
  }
  .Section2B cx-banner cx-generic-link a cx-media img {
    display: none;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: landscape) {
  cx-page-layout.ProductListPageTemplate .ProductLeftRefinements,
  cx-page-layout.ProductGridPageTemplate .ProductLeftRefinements {
    max-width: 90% !important;
    margin-left: 0px;
    margin-right: 0px;
    background: #ffffff;
  }
  cx-page-layout.ProductListPageTemplate .ProductLeftRefinements,
  cx-page-layout.ProductGridPageTemplate .ProductLeftRefinements {
    margin: 0px;
  }
}

//ipad changes
@media (max-width: 991.98px) {
  cx-navigation-ui.flyout .is-open .wrapper .childs cx-generic-link {
    border-bottom: 1px solid #edf6fb !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 640px) and (orientation: portrait) {
  header .SiteLinks cx-navigation-ui nav {
    display: block;
    background-color: #2a343f !important;
    color: white;
    font-size: 12px;
    width: 232%;
  }
  header cx-navigation-ui.flyout h5:hover {
    color: #fff;
  }
  header .SiteContext :nth-child(2) {
    display: block;
    margin: 0px -9px 0px 0px;
  }
  header .SiteContext cx-site-context-selector {
    width: 100%;
    background-color: #2a343f;
  }
  .header cx-site-context-selector select {
    padding: 10px 10px 10px 1px;
  }
  header .SiteContext :nth-child(3) {
    display: none;
  }
  header .SiteLinks cx-link {
    display: block;
    background-color: #2a343f;
    width: 100%;
    height: 56px;
    border-top: 1px solid #fff;
    padding: 10px 13px 0px 12px;
  }
  cx-page-layout.LandingPage2Template
    cx-page-slot.Section2B
    .user-form
    .customer-login {
    display: none;
  }
}

@media screen and (min-width: 360px) and (max-width: 640px) and (orientation: landscape) {
  cx-page-layout.LandingPage2Template cx-page-slot cx-banner cx-media img {
    display: block;
    height: 457px !important;
  }
  cx-page-slot.SiteContext.has-components {
    display: contents;
  }
  header .SiteContext :nth-child(2) {
    display: inherit !important;
    padding: 0px;
    width: 33% !important;
    margin: 0px !important;
    height: 34.5px;
  }
  .LandingPage2Template cx-paragraph .home-banner-left h2 {
    position: absolute;
    margin: 26px 0px 0px 0px;
    width: 100%;
    font-size: 30px !important;
    margin: 26px 0px 0px 0px !important;
  }
  cx-page-layout.ProductListPageTemplate .ProductLeftRefinements,
  cx-page-layout.ProductGridPageTemplate .ProductLeftRefinements {
    max-width: 90% !important;
  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  .LandingPage2Template .header .SiteLinks cx-link cx-generic-link a {
    text-align: right;
    padding: 10px 19px 0px 0px;
  }
  cx-navigation-ui.flyout > nav > .wrapper {
    background: white;
    z-index: 999;
    position: absolute;
  }
  .header .SearchBox cx-searchbox label {
    width: 275px;
    min-width: 286px;
    max-width: 275px;
  }
  .header cx-site-context-selector select {
    width: 30%;
  }
  header .SiteContext :nth-child(2) {
    display: block !important;
    padding: 0px;
    width: 30%;
    margin: 0px !important;
    height: 33.5px;
  }
  .header .SiteLinks cx-link:nth-of-type(2) {
    display: none;
  }
  .header .SiteLinks cx-link:nth-of-type(2) {
    display: none;
  }
  .header .SiteLinks cx-link:nth-of-type(1) {
    flex: none !important;
    text-align: right;
    padding: 5px 10px 5px 21px;
    border-top: 3px solid #fff;
    height: 45px;
    margin: -5px 0px 0px 0px;
  }
}

.header .SearchBox cx-searchbox label {
  width: 388px;
}

app-quick-order cx-searchbox label {
  max-width: 375px;
  min-width: 375px;
  width: 375px;
}

@media screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
  header .NavigationBar {
    padding: 0px 0px 20px 0px;
  }
  .header .SiteLogo {
    width: 43% !important;
    margin: 0px 0px 0px -14px !important;
    cx-banner cx-generic-link {
      margin: 0px 0px 0px 57px;
    }
  }
  header cx-navigation-ui.flyout > nav > h5 {
    font-size: 14px;
  }
  .header .MiniCart {
    margin: 0px -1px 0px 0px !important;
    width: 21.4% !important;
  }
  .header .SearchBox {
    width: 34.9% !important;
    margin: 0px 8px 0px -1px;
    cx-searchbox {
      padding: 0px 0px 0px 33px;
    }
  }
  header .SiteLinks cx-link cx-generic-link a {
    padding: 2px 0px !important;
    width: auto !important;
  }
  header .SiteContext {
    flex: 50%;
    display: contents !important;
  }
  .header .SiteLinks {
    display: flex;
  }
  .header .SiteLinks cx-link {
    border: none !important;
  }
  header .SiteContext :nth-child(2) {
    width: 22% !important;
  }
  header .SiteContext cx-paragraph {
    width: 24.7%;
  }
  header .SiteContext :nth-child(3) {
    display: none !important;
  }
  .header .SiteLinks cx-link:nth-of-type(1) {
    width: 80.6%;
  }
  header .SiteLinks > cx-navigation {
    margin: 0px -1px 0px -1px;
    width: 22%;
  }
  header .PreHeader {
    height: 40px;
  }
  header .header {
    background-color: #fff !important;
  }
  header cx-navigation-ui.flyout > nav > h5 {
    width: auto;
  }
  .header .NavigationBar .flyout nav:nth-of-type(2) cx-generic-link a {
    padding: 4px 0px 0px 0px;
  }
  .header .NavigationBar .flyout nav:nth-of-type(3) h5 {
    margin-left: 115px;
  }
}

@media screen and (min-width: 414px) and (max-width: 736px) and (orientation: landscape) {
  header .SiteLogo {
    width: 288px !important;
  }
  header .SiteContext cx-paragraph {
    width: 36%;
  }
  header .SiteLinks cx-link cx-generic-link a {
    padding: 5px 9px 0px 0px !important;
    width: 706px !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 800px) and (orientation: landscape) {
  header .SiteLogo {
    width: 330px !important;
  }
}

.BottomHeaderSlot app-custom-indirect-banner {
  display: flex;
  width: 100%;
  margin: 0px !important;
  padding: 20px 0px 0px 20px;
  max-width: 100%;
}

